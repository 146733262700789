import React from "react"

//Partials
// import { Routes } from "./routes/Routes"
//Styles
import "./App.scss"
import { useAuthContext } from "./lib/context/AuthContext/AuthContext"
import AuthenticatedRoutes from "./routes/AuthenticatedRoutes"
import RestrictedRoutes from "./routes/RestrictedRoutes"
import { ReactComponent as LogoSvg } from "./assets/icons/logo.svg"
function App() {
  const authCtx = useAuthContext()

  if (authCtx.isLoading)
    return (
      <div className="App__logo">
        <LogoSvg />
      </div>
    )
  return (
    <div className="App">
      {authCtx.isAuthenticated ? <AuthenticatedRoutes /> : <RestrictedRoutes />}
    </div>
  )
}

export default App
