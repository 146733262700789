import React, { useEffect, useState } from "react"
import { RouteComponentProps } from "react-router-dom"
import { productType } from "../../staticdata/Data"
import { useConfirmation } from "../../lib/context/ConfirmationContext/ConfirmationContext"
import { CustomSpinner } from "../../components/shared/CustomSpinner/CustomSpinner"
import { useErrorHandler } from "../../lib/hooks/useErrorHandler"
import { useApiCall } from "../../lib/hooks/useApiCall"
import { ErrorMessage } from "../../components/shared/ErrorMessage/ErrorMessage"
import Select from "../../components/shared/Select/Select"
import Button from "../../components/shared/Button/Button"
import Table from "../../components/shared/Table/Table"
import Image from "../../components/shared/Image/Image"
import * as API from "../../api/Api"

import "./Products.scss"

export const Products = (props: RouteComponentProps) => {
  const confirmationContext = useConfirmation()
  const errorHandler = useErrorHandler()
  // const [initialCategory, setInitialCategory] = useState<string>()
  const [deleteCheck, setDeleteCheck] = useState<string>()
  const [deleting, setDeleting] = useState(false)
  const [categoryId, setCategoryId] = useState<string | null>()
  const { loading, data: categories, error } = useApiCall(API.getAllCategories)
  const {
    loading: isLoading,
    data: getProducts,
    error: productsError,
    reload
  } = useApiCall(API.getAllProducts, [categoryId])
  const selectCategories = categories?.category.map((cat) => ({
    value: cat.id,
    label: cat.name
  }))
  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    window.sessionStorage.setItem("selected", e.target.value)
    setCategoryId(e.target.value)
  }

  useEffect(() => {
    const option = window.sessionStorage.getItem("selected")
    setCategoryId(option)
    reload()
  }, [])

  // useEffect(() => {
  //   const option = window.sessionStorage.getItem("selected")
  //   const selected = categories?.category.find((cat) => {
  //     return cat.id === option
  //   })
  //   setInitialCategory(selected?.name)
  // }, [categoryId])

  // console.log("initialCategory", initialCategory)
  // if(window.sessionStorage.length===0){}
  const handleDelete = async (productId: string) => {
    try {
      await confirmationContext.confirm("A jeni i sigurte?")
      setDeleteCheck(productId)
      setDeleting(true)
      const res = await API.deleteProduct(productId)
      setDeleting(false)
      reload()
    } catch (e) {
      errorHandler.handleError(e)
    }
  }

  const columns = [
    {
      key: "product_photo",
      header: "Picture",
      sortable: false
    },
    {
      key: "product_name",
      header: "Name",
      sortable: true
    },
    {
      key: "product_cost",
      header: "Price",
      sortable: true
    },
    {
      key: "product_actions",
      header: "Actions",
      sortable: false
    }
  ]
  const rows = getProducts?.product?.map((product: productType) => ({
    product_photo: (
      <div className="product_photo">
        <Image file={product.media} alt="product" />
      </div>
    ),
    product_name: product.product_name,
    product_cost: product.price,
    product_actions: (
      <>
        {deleteCheck === product.id ? (
          <Button color="danger" label="Delete" disabled={deleting}>
            <CustomSpinner />
          </Button>
        ) : (
          <Button
            color="danger"
            label="Delete"
            onClick={() => handleDelete(product.id)}
          >
            Delete
          </Button>
        )}

        <Button
          color="secondary"
          className={"Products__change-btn"}
          onClick={() => props.history.push(`products/edit/${product.id}`)}
        >
          Edit
        </Button>
      </>
    )
  }))

  const selectValue = window.sessionStorage.getItem("selected") ?? ""

  const selectCategory = (
    <Select
      className="custom-select"
      value={selectValue}
      type="select"
      name="Select category"
      disabledOption="Select category"
      options={selectCategories}
      onChange={handleChange}
    />
  )

  return (
    <div className="Products">
      <Table
        loading={loading}
        textLoading={isLoading}
        title="Products"
        columns={columns}
        rows={rows}
        button={selectCategory}
        actions={
          <Button
            color="secondary"
            onClick={() => props.history.push(`products/${categoryId}`)}
          >
            Add Product
          </Button>
        }
      />
      <ErrorMessage error={errorHandler.error || error} />
    </div>
  )
}
