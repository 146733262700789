import React, { ChangeEvent, useEffect, useState } from "react"
import "./Input.scss"
import { FormFeedback, FormGroup, Input as InputRS, Label } from "reactstrap"
import cs from "classnames"

export type InputType =
  | "text"
  | "email"
  | "select"
  | "file"
  | "radio"
  | "checkbox"
  | "textarea"
  | "button"
  | "reset"
  | "submit"
  | "date"
  | "datetime-local"
  | "hidden"
  | "image"
  | "month"
  | "number"
  | "range"
  | "search"
  | "tel"
  | "url"
  | "week"
  | "password"
  | "datetime"
  | "time"
  | "color"

export interface InputProps {
  type: InputType
  name: string
  id?: string
  float?: boolean
  placeholder?: string
  options?: { value: string; label: string }[]
  value?: string | number | undefined
  onChange: (e: ChangeEvent<any>) => void
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  valid?: boolean
  invalid?: boolean
  className?: string
  hidden?: boolean
  accept?: string
  error?: string
  children?: React.ReactNode
}

const Input = (props: InputProps) => {
  const { onChange, onBlur, placeholder, className, error, ...rest } = props
  const [isFloating, setIsFloating] = useState(false)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      setIsFloating(true)
    }
    onChange(e)
  }

  useEffect(() => {
    if (props.float) setIsFloating(true)
  }, [])

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    !props.value && setIsFloating(false)
    onBlur && onBlur(e)
  }
  return (
    <FormGroup>
      <Label
        className={cs("Input__label", isFloating && "Input__label--floated")}
        htmlFor={props.id}
      >
        {placeholder}
      </Label>
      <InputRS
        {...rest}
        min="0"
        className={cs("Input", className && className)}
        onChange={handleChange}
        onFocus={() => setIsFloating(true)}
        onBlur={handleBlur}
      >
        {props.children}
      </InputRS>

      <FormFeedback>{error}</FormFeedback>
    </FormGroup>
  )
}

export default Input
