import React, { useCallback, useEffect, useState } from "react"
import { ProductFormik } from "./lib/useProductFormik"
import { useApiCall } from "../../lib/hooks/useApiCall"
import { CustomSpinner } from "../shared/CustomSpinner/CustomSpinner"
import { ErrorMessage } from "../../components/shared/ErrorMessage/ErrorMessage"
import Input from "../shared/Input/Input"
import Button from "../shared/Button/Button"
import Image from "../shared/Image/Image"
import Select from "../shared/Select/Select"
import File from "../shared/File/File"
import * as API from "../../api/Api"

import "./ProductForm.scss"

interface ProductFormProps {
  button_label: string
  title: string
  formik: ProductFormik
  error: string
}

const ProductForm = (props: ProductFormProps) => {
  const { title, formik } = props
  const { loading, data, error } = useApiCall(API.getAllCategories)

  const categories = data?.category.map((cat) => ({
    value: cat.id,
    label: cat.name
  }))

  return (
    <div className="ProductForm">
      <div className="ProductForm__header">
        <h5>{title}</h5>
      </div>
      <div className="ProductForm__content">
        <form onSubmit={formik.handleSubmit}>
          <div className="ProductForm__content-left">
            <Input
              type="text"
              name="product_name"
              id="product_name"
              placeholder="Product name"
              float={formik.initialValues.product_name ? true : false}
              value={formik.values.product_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              invalid={
                !!(formik.errors.product_name && formik.touched.product_name)
              }
              error={
                formik.touched.product_name && formik.errors.product_name
                  ? formik.errors.product_name
                  : ""
              }
            />

            <Select
              type="select"
              name="selectCategory"
              id="selectCategory"
              placeholder="Select Category"
              float={formik.initialValues.selectCategory ? true : false}
              value={formik.values.selectCategory}
              onChange={formik.handleChange}
              options={categories}
              onBlur={formik.handleBlur}
              invalid={
                !!(
                  formik.errors.selectCategory && formik.touched.selectCategory
                )
              }
              error={
                formik.touched.selectCategory && formik.errors.selectCategory
                  ? formik.errors.selectCategory
                  : ""
              }
            />

            <File
              name="selectPhoto"
              value={formik.values.selectPhoto}
              onBlur={formik.handleBlur}
              onChange={(file) => {
                formik.setFieldValue("selectPhoto", file, true)
              }}
              setFieldValue={formik.setFieldValue}
              error={
                formik.touched.selectPhoto && formik.errors.selectPhoto
                  ? formik.errors.selectPhoto
                  : ""
              }
            />

            <Input
              className="ProductForm__content-left__cost"
              type="number"
              name="cost"
              id="cost"
              placeholder="Price"
              float={formik.initialValues.cost ? true : false}
              onChange={formik.handleChange}
              value={formik.values.cost}
              onBlur={formik.handleBlur}
              invalid={!!(formik.errors.cost && formik.touched.cost)}
              error={
                formik.errors.cost && formik.touched.cost
                  ? formik.errors.cost
                  : ""
              }
            />
            <ErrorMessage error={props.error} />
            <Button
              className="ProductForm__content-button"
              color="secondary"
              type="submit"
              disabled={!formik.dirty}
            >
              {formik.isSubmitting ? (
                <div>
                  <CustomSpinner />
                </div>
              ) : (
                "Submit"
              )}
            </Button>
          </div>

          <div className="ProductForm__content-right">
            <Image file={formik.values.selectPhoto} alt="Product Image" />
          </div>
        </form>
      </div>
    </div>
  )
}

export default ProductForm
