import React from "react"
import { Icon } from "../shared/Icon/Icon"
import { useUIContext } from "../../lib/context/UIContext/UIContext"
import Nav from "./Nav/Nav"
import cs from "classnames"
import "./SideBar.scss"

const SideBar = () => {
  const uiContext = useUIContext()

  return (
    <div
      className={cs("SideBar", !uiContext.isSideBarOpen && "SideBar--closed")}
    >
      <div className="SideBar__header">
        <Icon icon="logo" className="SideBar__header__logo" />
        <Icon
          icon="clear"
          className="SideBar__header__mobile-clear-icon"
          onClick={() => uiContext.toggleSideBar()}
        />
      </div>

      <Nav />
    </div>
  )
}

export default SideBar
