import { FormikHelpers, useFormik } from "formik"
import * as Yup from "yup"

export interface CategoryFormFields {
  category_name: string
  selectedCategoryPhoto: string
}

interface UseCategoryFormOptions {
  initialValues?: CategoryFormFields
  onSubmit: (
    values: CategoryFormFields,
    formikHelpers: FormikHelpers<CategoryFormFields>
  ) => Promise<any>
}

export const useCategoryFormik = (options: UseCategoryFormOptions) => {
  const CategoryFormSchema = Yup.object().shape({
    category_name: Yup.string().required("Please add Category name"),
    selectedCategoryPhoto: Yup.mixed()
      .required("Please choose a photo")
      .test("type", "Supported formats are .jpeg, .png ", (value) => {
        return (
          value && (value.type === "image/jpeg" || value.type === "image/png")
        )
      })
  })

  return useFormik({
    initialValues: {
      category_name: "",
      selectedCategoryPhoto: "",
      ...options.initialValues
    },
    // validateOnMount: true,
    isInitialValid: false,
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: CategoryFormSchema,
    onSubmit: async (values, formikHelpers) => {
      await options.onSubmit(values, formikHelpers)
    }
  })
}
export type CategoryFormik = ReturnType<typeof useCategoryFormik>
