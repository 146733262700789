import React from "react"
import {AppRoutes, RouteType} from "./Routes"
import {Redirect, Switch} from "react-router-dom"
import {RestrictedRoute} from "./RestrictedRoute"

const RestrictedRoutes = () => {
    const routes = AppRoutes.filter(
        (route) => route.type === RouteType.RESTRICTED
    )
    return (
        <Switch>
            {routes.map((route) => {
                const {path, ...rest} = route
                return <RestrictedRoute {...rest} key={`${path}`} path={`/${path}`}/>
            })}
            <Redirect to={"/login"}/>
        </Switch>
    )
}

export default RestrictedRoutes
