import React, { useEffect, useState } from "react"
import { HandleLoadingState } from "../../HandleLoadingState/HandleLoadingState"
import { HandleErrorState } from "../../HandleErrorState/HandleErrorState"

import { Icon } from "../Icon/Icon"
import Message from "../Message/Message"
import "./Table.scss"

interface Row {
  [key: string]: JSX.Element | string
}
interface Column {
  key: string
  header: string
  sortable: boolean
}

interface TableProps {
  loading: boolean
  title: String
  columns: Array<object>
  rows: Row[] | undefined
  actions: JSX.Element
  textLoading?: boolean
  button?: JSX.Element
}

const Table = (props: TableProps) => {
  const [rowsData, setRowsData] = useState<any>([])
  const [switchSort, setSwitchSort] = useState<boolean>(false)
  const [sortKey, setSortKey] = useState("")

  useEffect(() => {
    setRowsData(props.rows)
  }, [props.rows])
  const handleSort = (key: string) => {
    setSortKey(key)
    setSwitchSort((prevState) => !prevState)

    let rowsDataCopy: any = [...rowsData]
    rowsDataCopy.sort(compareByDesc(key))
    setRowsData(rowsDataCopy)
  }

  function compareByDesc(key: string) {
    if (switchSort) {
      return function (a: Row, b: Row) {
        if (a[key] < b[key]) return -1
        if (a[key] > b[key]) return 1
        return 0
      }
    } else {
      return function (a: Row, b: Row) {
        if (a[key] > b[key]) return -1
        if (a[key] < b[key]) return 1
        return 0
      }
    }
  }

  return (
    <HandleLoadingState loading={props.loading}>
      <HandleErrorState>
        <div className="Table">
          <div className="Table__header">
            <h5>{props.title}</h5>
            <div className="Table__select">{props.button}</div>
            <div className="Table__actions">{props.actions}</div>
          </div>
          <div className="Table__wrapper">
            <table className="Table__content">
              <thead>
                <tr>
                  {props.columns.map((col: any, idx: number) => {
                    return (
                      <th key={idx}>
                        {"header" in col && col.header}

                        {col.sortable && (
                          <Icon
                            className="Table__content__icon"
                            icon={
                              sortKey !== col.key
                                ? "sort"
                                : sortKey === col.key && !switchSort
                                ? "sort-up"
                                : "sort-down"
                            }
                            onClick={() => handleSort(col.key)}
                          />
                        )}
                      </th>
                    )
                  })}
                </tr>
              </thead>
              <tbody>
                {rowsData?.length === 0 || rowsData === undefined ? (
                  props.textLoading ? (
                    <Message text="Loading..." type="info" />
                  ) : (
                    <Message text="Nuk ka te dhena" type="info" />
                  )
                ) : (
                  rowsData?.map((row: Row, index: number) => {
                    return (
                      <tr key={index}>
                        {props.columns.map((rowColumn: any, idx: number) => {
                          return <td key={idx}>{row[rowColumn.key]}</td>
                        })}
                      </tr>
                    )
                  })
                )}
              </tbody>
            </table>
          </div>
        </div>
      </HandleErrorState>
    </HandleLoadingState>
  )
}

export default Table
