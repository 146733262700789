import React from "react"
import Skeleton from "react-loading-skeleton"
import "./ProductFormSkeleton.scss"

export const ProductFormSkeleton = () => {
  return (
    <div className="ProductFormSkeleton">
      <div className="ProductFormSkeleton__header">
        <h5>
          <Skeleton height={25} width={100} />
        </h5>
      </div>
      <div className="ProductFormSkeleton__wrapper">
        <form>
          <div className="ProductFormSkeleton__content">
            <div className="ProductFormSkeleton__content-left">
              <div>
                <Skeleton height={25} width={450} />
              </div>
              <div>
                <Skeleton height={25} width={450} />
              </div>
              <div>
                <Skeleton height={25} width={450} />
              </div>
              <div>
                <Skeleton height={25} width={450} />
              </div>
              <div>
                <Skeleton height={25} width={80} />
              </div>
            </div>
            <div className="ProductFormSkeleton__content-left">
              <Skeleton height={300} width={300} />
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
