import React, { useEffect } from "react"
import Button from "../shared/Button/Button"
import { Modal } from "../shared/Modal/Modal"
import { useRouter } from "../../lib/hooks/useRouter"

import "./ConfirmationModal.scss"

export interface Props {
  showCloseButton?: boolean
  confirmationData: {
    text?: string
    isOpen: boolean
    isLoading?: boolean
    onConfirm: () => void
    onCancel: () => void
  }
}

export default function ConfirmationModal(props: Props) {
  const router = useRouter()

  useEffect(() => {
    cancel()
  }, [router.location.pathname]) // eslint-disable-line react-hooks/exhaustive-deps

  const confirm = () => {
    if (props.confirmationData.onConfirm) {
      props.confirmationData.onConfirm()
    }
  }

  const cancel = () => {
    if (props.confirmationData.onCancel) {
      props.confirmationData.onCancel()
    }
  }

  return (
    <Modal isOpen={props.confirmationData.isOpen} toggle={() => cancel()}>
      <div className="ConfirmationModal">
        <h4 className="ConfirmationModal__text">
          {props.confirmationData.text}
        </h4>
        <div className="ConfirmationModal__buttons">
          <Button
            className="ConfirmationModal__buttons__left"
            color="danger"
            onClick={confirm}
          >
            Yes
          </Button>
          <Button
            className="ConfirmationModal__buttons__right"
            color="secondary"
            onClick={cancel}
          >
            No
          </Button>
        </div>
      </div>
    </Modal>
  )
}
