import React, { useState } from "react"
import { FormFeedback, FormGroup } from "reactstrap"
import Input from "../Input/Input"
import { Icon } from "../Icon/Icon"
import { splitAndGetLast } from "../../../lib/helpers/splitAndGetLast"
import "./File.scss"

interface FileProps {
  name: string
  value: string | File
  error?: string
  onChange: (e: React.ChangeEvent) => void
  onBlur: (e: React.FocusEvent<HTMLInputElement>) => void
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
}

const File = (props: FileProps) => {
  const [fileName, setFileName] = useState("")

  const handleChange = (e: React.ChangeEvent<any>) => {
    if (e.target.files[0] === undefined) {
      return
    }
    setFileName(e.currentTarget.files[0]?.name)
    props.onChange(e.target.files[0])
  }
  const clearImage = (e: React.MouseEvent<any>) => {
    setFileName("")
    props.setFieldValue(props.name, "", true)
  }

  return (
    <FormGroup>
      <div className="File">
        <label htmlFor={props.name} className="File__file">
          <Input
            type="file"
            name={props.name}
            id={props.name}
            accept="image/png, image/jpeg"
            value=""
            onChange={handleChange}
            onBlur={props.onBlur}
          />
          <span role={"button"} className="File__file__button">
            Choose File
          </span>
          <span className="File__file__text">
            {props.value && fileName
              ? fileName
              : props.value
              ? props.value
              : "Nuk ka foto te zgjedhur"}
          </span>
        </label>
        {props.value && (
          <Icon icon="clear" className="File-clear" onClick={clearImage} />
        )}
      </div>

      {props.error && (
        <FormFeedback style={{ display: "block" }}>{props.error}</FormFeedback>
      )}
    </FormGroup>
  )
}

export default File
