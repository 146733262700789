import React from "react"
import { CategoryFormik } from "./lib/useCategoryFormik"
import { CustomSpinner } from "../../components/shared/CustomSpinner/CustomSpinner"
import Input from "../shared/Input/Input"
import Button from "../shared/Button/Button"
import Image from "../shared/Image/Image"
import File from "../shared/File/File"

import "./CategoryForm.scss"
import { ErrorMessage } from "../shared/ErrorMessage/ErrorMessage"

interface CategoryFormProps {
  button_label: string
  title: string
  formik: CategoryFormik
  error: string
}

const CategoryForm = (props: CategoryFormProps) => {
  const { button_label, title, formik } = props

  return (
    <div className="CategoryForm">
      <div className="CategoryForm__header">
        <h5>{title}</h5>
      </div>
      <div className="CategoryForm__content">
        <form onSubmit={formik.handleSubmit}>
          <div className="CategoryForm__content-left">
            <Input
              className="CategoryForm__input"
              type="text"
              name="category_name"
              id="category_name"
              placeholder="Category name"
              float={formik.initialValues.category_name ? true : false}
              value={formik.values.category_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              invalid={
                !!(formik.errors.category_name && formik.touched.category_name)
              }
              error={
                formik.touched.category_name && formik.errors.category_name
                  ? formik.errors.category_name
                  : ""
              }
            />

            <File
              name="selectedCategoryPhoto"
              value={formik.values.selectedCategoryPhoto}
              // float={formik.initialValues.selectedCategoryPhoto ? true : false}
              onChange={(file) => {
                formik.setFieldValue("selectedCategoryPhoto", file, true)
              }}
              onBlur={formik.handleBlur}
              setFieldValue={formik.setFieldValue}
              error={
                formik.touched.selectedCategoryPhoto &&
                formik.errors.selectedCategoryPhoto
                  ? formik.errors.selectedCategoryPhoto
                  : ""
              }
            />
            <ErrorMessage error={props.error} />
            <Button
              className="CategoryForm-button"
              color="secondary"
              type="submit"
              disabled={!formik.dirty}
            >
              {formik.isSubmitting ? <CustomSpinner /> : "Submit"}
            </Button>
          </div>
          <div className="CategoryForm__content--right">
            <Image
              file={formik.values.selectedCategoryPhoto}
              alt="Category Image"
            />
          </div>
        </form>
      </div>
    </div>
  )
}

export default CategoryForm
