import React, { useState } from "react"
import { UIContext, UIContextType } from "./UIContext"
import { useWindowWidth } from "../../hooks/useWindowWidth"

interface UIContextProviderProps {
  children: React.ReactNode | null
}

interface State {
  isSideBarOpen: boolean
  isMenuOpen: boolean
}

interface UIContextProviderState {
  isSideBarOpen: boolean
  isMenuOpen: boolean
}

export const UIContextProvider = (props: UIContextProviderProps) => {
  const windowWidth = useWindowWidth()
  const [state, setState] = useState<UIContextProviderState>({
    isSideBarOpen: windowWidth > 992,
    isMenuOpen: windowWidth > 992
  })

  const toggleSideBar = () =>
    setState((prevState) => ({
      ...prevState,
      isSideBarOpen: !prevState.isSideBarOpen
    }))
  const toggleMenu = () =>
    setState((prevState) => ({
      ...prevState,
      isMenuOpen: !prevState.isMenuOpen
    }))
  const toggleSideBarAndMenu = () => {
    toggleSideBar()
    toggleMenu()
  }
  const context: UIContextType = {
    isSideBarOpen: state.isSideBarOpen,
    isMenuOpen: state.isMenuOpen,
    toggleSideBar: toggleSideBar,
    toggleMenu: toggleMenu,
    toggleSideBarAndMenu: toggleSideBarAndMenu
  }

  return (
    <UIContext.Provider value={context}>{props.children}</UIContext.Provider>
  )
}
