import React from "react"
import "./ErrorHandler.scss"
interface Props {
  error?: any
}

export const ErrorHandler = (props: Props) => {
  return props.error ? (
    <div className="ErrorHandler">{props.error}</div>
  ) : (
    <div></div>
  )
}
