import { apiRequest } from "./Api"
export interface ProductInput {
  product_name: string
  selectCategory: string
  selectPhoto: File
  cost: number
}
export interface ProductsResponse {
  product: [
    {
      category_id: string
      id: string
      price: string
      product_name: string
      media: string
    }
  ]
}
export interface ProductResponse {
  product: {
    category_id: string
    id: string
    product_name: string
    price: string
    media: string
  }
}
export interface UpdateProductInput {
  name: string
  price: string
  productId: string
  image: string | File
}
export interface CreateProductInput {
  name: string
  price: string
  categoryId: string
  image: string | File
}

export const createProduct = async (data: FormData) =>
  apiRequest<FormData, undefined>("post", "dashboard/product/create", data)

export const getAllProducts = async (categoryId?: string | null) =>
  apiRequest<undefined, ProductsResponse>(
    "get",
    `dashboard/product/get-products-by-category?categoryId=${categoryId}`
  )

export const getProductById = async (productId: string) =>
  apiRequest<string, ProductResponse>(
    "get",
    `dashboard/product/product-by-id?productId=${productId}`
  )
export const updateProduct = async (data: FormData) =>
  apiRequest<FormData, undefined>("post", "dashboard/product/update", data)
export const deleteProduct = async (productId: string) =>
  apiRequest<string, any>(
    "delete",
    `dashboard/product/delete?productId=${productId}`
  )
