import React from "react"
import { Spinner } from "reactstrap"
import "./CustomSpinner.scss"

export const CustomSpinner = () => {
  return (
    <div className="CustomSpinner">
      <Spinner className="CustomSpinner__spinner" />
    </div>
  )
}
