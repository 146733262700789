import React, { useState } from "react"
import { RouteComponentProps } from "react-router-dom"
import ProductForm from "../../../components/ProductForm/ProductForm"
import { useProductFormik } from "../../../components/ProductForm/lib/useProductFormik"
import "./AddProduct.scss"
import * as API from "../../../api/Api"
import { useErrorHandler } from "../../../lib/hooks/useErrorHandler"

interface Props {
  id: string
}

const AddProduct = (props: RouteComponentProps<Props>) => {
  const errorHandler = useErrorHandler()
  const formik = useProductFormik({
    onSubmit: async (values, formikHelpers) => {
      try {
        // console.log(values)
        const formData = new FormData()
        formData.append("name", values.product_name)
        formData.append("image", values.selectPhoto)
        formData.append("price", values.cost)
        formData.append("categoryId", values.selectCategory)
        const res = await API.createProduct(formData)
        formikHelpers.resetForm()
        props.history.push("/products")
      } catch (e) {
        errorHandler.handleError(e)
      } finally {
      }
    }
  })
  return (
    <div className="AddProduct">
      <ProductForm
        button_label="Submit"
        title="Add Product"
        formik={formik}
        error={errorHandler.error}
      />
    </div>
  )
}

export default AddProduct
