import React from "react"
import Skeleton from "react-loading-skeleton"
import "./CategoryFormSkeleton.scss"

export const CategoryFormSkeleton = () => {
  return (
    <div className="CategoryFormSkeleton">
      <div className="CategoryFormSkeleton__header">
        <h5>
          <Skeleton height={25} width={100} />
        </h5>
      </div>
      <div className="CategoryFormSkeleton__wrapper">
        <form>
          <div className="CategoryFormSkeleton__content">
            <div className="CategoryFormSkeleton__content-left">
              <div>
                <Skeleton height={25} width={450} />
              </div>
              <div>
                <Skeleton height={25} width={450} />
              </div>
              <div>
                <Skeleton height={25} width={80} />
              </div>
            </div>
            <div className="CategoryFormSkeleton__content-right">
              <Skeleton height={200} width={200} />
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
