import React from "react"
import Skeleton from "react-loading-skeleton"
import "./TableSkeleton.scss"

const TableSkeleton = () => {
    return (
        <div className="TableSkeleton">
            <div className="TableSkeleton__header">
                <h5>
                    <Skeleton height={25} width={100}/>
                </h5>
                <Skeleton style={{borderRadius: 20}} width={120} height={40}/>
            </div>
            <div className="Table__wrapper">
                <table className="TableSkeleton__content">
                    <thead>
                    <tr>
                        <th>
                            <Skeleton width={55}/>
                        </th>
                        <th>
                            <Skeleton width={55}/>
                        </th>
                        <th>
                            <Skeleton width={65}/>
                        </th>
                        <th>
                            <Skeleton width={90}/>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>
                            <Skeleton duration={2} circle={true} width={40} height={40}/>
                        </td>
                        <td>
                            <Skeleton duration={2} width={100}/>
                        </td>
                        <td>
                            <Skeleton duration={2} width={40}/>
                        </td>
                        <td className="TableSkeleton__content__buttons">
                            <div>
                                <Skeleton
                                    duration={2}
                                    style={{borderRadius: 20}}
                                    width={80}
                                    height={36}
                                />
                            </div>
                            <div>
                                <Skeleton
                                    duration={2}
                                    style={{borderRadius: 20, marginLeft: 10}}
                                    width={100}
                                    height={36}
                                />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <Skeleton duration={2} circle={true} width={40} height={40}/>
                        </td>
                        <td>
                            <Skeleton duration={2} width={100}/>
                        </td>
                        <td>
                            <Skeleton duration={2} width={40}/>
                        </td>
                        <td className="TableSkeleton__content__buttons">
                            <div>
                                <Skeleton
                                    duration={2}
                                    style={{borderRadius: 20}}
                                    width={80}
                                    height={36}
                                />
                            </div>
                            <div>
                                <Skeleton
                                    duration={2}
                                    style={{borderRadius: 20, marginLeft: 10}}
                                    width={100}
                                    height={36}
                                />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <Skeleton duration={2} circle={true} width={40} height={40}/>
                        </td>
                        <td>
                            <Skeleton duration={2} width={100}/>
                        </td>
                        <td>
                            <Skeleton duration={2} width={40}/>
                        </td>
                        <td className="TableSkeleton__content__buttons">
                            <div>
                                <Skeleton
                                    duration={2}
                                    style={{borderRadius: 20}}
                                    width={80}
                                    height={36}
                                />
                            </div>
                            <div>
                                <Skeleton
                                    duration={2}
                                    style={{borderRadius: 20, marginLeft: 10}}
                                    width={100}
                                    height={36}
                                />
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default TableSkeleton
