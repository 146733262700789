import React from "react"
import {AppRoutes, RouteType} from "./Routes"
import {Header} from "../partials/Header/Header"
import {Redirect, Switch} from "react-router-dom"
import {PrivateRoute} from "./PrivateRoute"
import cs from "classnames"
import SideBar from "../components/SideBar/SideBar"
import {useUIContext} from "../lib/context/UIContext/UIContext"
import "./AuthenticatedRoutes.scss"

const AuthenticatedRoutes = () => {
    const uiContext = useUIContext()

    const routes = AppRoutes.filter((route) => route.type === RouteType.PRIVATE)
    return (
        <div className="AuthenticatedRoutes">
            <SideBar/>

            <div
                className={cs(
                    "AuthenticatedRoutes__content",
                    !uiContext.isSideBarOpen && "AuthenticatedRoutes__content--closed"
                )}
            >
                <Header/>
                <Switch>
                    {routes.map((route) => {
                        const {path, ...rest} = route
                        return <PrivateRoute {...rest} key={`${path}`} path={`/${path}`}/>
                    })}
                    <Redirect to="/products"/>
                </Switch>
            </div>
        </div>
    )
}
export default AuthenticatedRoutes
