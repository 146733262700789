import React from "react"
import {IconDefinition, IconSvg} from "./Icon.generated"
import cs from "classnames"
import "./Icon.scss"

export * from "./Icon.generated"

interface Props {
    icon?: IconDefinition
    alt?: string
    style?: React.CSSProperties
    className?: string
    spin?: boolean
    onClick?: (e: any) => void
}

export function Icon(props: Props) {
    if (!props.icon) {
        return null
    }

    return (
        <div
            onClick={props.onClick}
            style={props.style}
            className={cs(
                "Icon",
                props.spin ? "Icon--spin" : undefined,
                props.className
            )}
        >
            {IconSvg[props.icon]}
        </div>
    )
}
