import React from "react"
import { RouteComponentProps } from "react-router-dom"

import "./EditCategory.scss"
import * as API from "../../../api/Api"
import EditCategoryForm from "../../../components/EditCategoryForm/EditCategoryForm"
import { useApiCall } from "../../../lib/hooks/useApiCall"
import { CategoryFormSkeleton } from "../../../components/CategoryForm/CategoryFormSkeleton/CategoryFormSkeleton"
interface Props {
  id: string
}

const EditCategory = (props: RouteComponentProps<Props>) => {
  const id = props.match.params.id
  const { loading, data: category, error } = useApiCall(API.getCategoryById, [
    id
  ])
  return loading ? (
    <CategoryFormSkeleton />
  ) : (
    <div className="EditCategory">
      {category && <EditCategoryForm categoryId={id} category={category} />}
    </div>
  )
}

export default EditCategory
