import React from "react"
import {Redirect, Route, RouteProps, useLocation} from "react-router-dom"
import {useAuthContext} from "../lib/context/AuthContext/AuthContext"

export const PrivateRoute = (props: RouteProps) => {
    const authCtx = useAuthContext()
    const location = useLocation()
    if (!authCtx.isAuthenticated) {
        return (
            <Redirect
                to={`login?redirect=${encodeURIComponent(location.pathname)}`}
            />
        )
    }

    return <Route {...props} />
}
