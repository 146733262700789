import React from "react"
import { RouteProps } from "react-router-dom"

import { Login } from "../pages/Login/Login"
import { Register } from "../pages/Register/Register"
import { ForgotPassword } from "../pages/ForgotPassword/ForgotPassword"
import { ResetPassword } from "../pages/ResetPassword/ResetPassword"
import Categories from "../pages/Categories/Categories"

import Users from "../pages/Users/Users"
import EditProduct from "../pages/Products/EditProduct/EditProduct"
import AddProduct from "../pages/Products/AddProduct/AddProduct"
import { Products } from "../pages/Products/Products"
import AddCategory from "../pages/Categories/AddCategory/AddCategory"
import EditCategory from "../pages/Categories/EditCategory/EditCategory"
import { AddUser } from "../pages/AddUser/AddUser"

export enum RouteType {
  PUBLIC,
  PRIVATE,
  RESTRICTED
}

interface AppRoute extends RouteProps {
  type?: RouteType
}

export const AppRoutes: AppRoute[] = [
  // Restricted Routes
  {
    type: RouteType.RESTRICTED,
    exact: true,
    path: "login",
    component: Login
  },
  {
    type: RouteType.RESTRICTED,
    exact: true,
    path: "register",
    component: Register
  },
  {
    type: RouteType.RESTRICTED,
    exact: true,
    path: "forgot-password",
    component: ForgotPassword
  },
  {
    type: RouteType.RESTRICTED,
    exact: true,
    path: "reset-password",
    component: ResetPassword
  },
  // Private Routes

  {
    type: RouteType.PRIVATE,
    exact: true,
    path: "products",
    component: Products
  },
  {
    type: RouteType.PRIVATE,
    exact: true,
    path: "categories",
    component: Categories
  },
  {
    type: RouteType.PRIVATE,
    exact: true,
    path: "users",
    component: Users
  },
  {
    type: RouteType.PRIVATE,
    exact: true,
    path: "products/:categoryId",
    component: AddProduct
  },
  {
    type: RouteType.PRIVATE,
    exact: true,
    path: "products/edit/:id",
    component: EditProduct
  },
  {
    type: RouteType.PRIVATE,
    exact: true,
    path: "categories/edit/:id",
    component: EditCategory
  },
  {
    type: RouteType.PRIVATE,
    exact: true,
    path: "categories/new",
    component: AddCategory
  },
  {
    type: RouteType.PRIVATE,
    exact: true,
    path: "user/new",
    component: AddUser
  }
]

// export const Routes = () => {
//   return (
//     <Switch>
//       {AppRoutes.map((r) => {
//         const { type, path, ...rest } = r;
//         if (type === RouteType.PRIVATE) {
//           return (
//             <PrivateRoute {...rest} key={`${r.path}`} path={`/${r.path}`} />
//           );
//         }
//         if (type === RouteType.RESTRICTED) {
//           return (
//             <RestrictedRoute {...rest} key={`${r.path}`} path={`/${r.path}`} />
//           );
//         }
//
//         return <PublicRoute {...rest} key={`${r.path}`} path={`/${r.path}`} />;
//       })}
//       <PublicRoute component={Page404} />
//     </Switch>
//   );
// };
