import React, { useState } from "react"
import ProductForm from "../ProductForm/ProductForm"
import * as API from "../../api/Api"
import { useProductFormik } from "../ProductForm/lib/useProductFormik"
import "./EditProductForm.scss"
import { RouteComponentProps } from "react-router"
import { useRouter } from "../../lib/hooks/useRouter"
import { useErrorHandler } from "../../lib/hooks/useErrorHandler"

interface EditProductFormProps {
  product: API.ProductResponse
  productId: string
}

export const EditProductForm = (props: EditProductFormProps) => {
  const router = useRouter()
  const { product } = props
  const errorHandler = useErrorHandler()

  const formik = useProductFormik({
    initialValues: {
      product_name: product.product.product_name,
      selectCategory: product.product.category_id,
      selectPhoto: product.product.media,
      cost: product.product.price
    },
    onSubmit: async (values, formikHelpers) => {
      try {
        const formData = new FormData()
        formData.append("name", values.product_name)
        formData.append("image", values.selectPhoto)
        formData.append("price", values.cost)
        formData.append("categoryId", values.selectCategory)
        formData.append("productId", props.productId)
        const res = await API.updateProduct(formData)
        formikHelpers.resetForm()
        router.history.push("/products")
      } catch (e) {
        errorHandler.handleError(e)
      } finally {
      }
    }
  })

  return (
    <ProductForm
      button_label="Submit changes"
      title="Edit Product"
      formik={formik}
      error={errorHandler.error}
    />
  )
}
