import React, { ChangeEvent } from "react"
import Input, { InputType } from "../Input/Input"

interface SelectProps {
  type: InputType
  name: string
  id?: string
  placeholder?: string
  float?: boolean
  value?: string | number
  onChange: (e: ChangeEvent<any>) => void
  options?: { value: string; label: string }[]
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  invalid?: boolean
  className?: string
  error?: string
  disabledOption?: string
}
const Select = (props: SelectProps) => {
  return (
    <Input {...props}>
      <option selected>{props.disabledOption}</option>
      {props.options?.map((option) => {
        return (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        )
      })}
    </Input>
  )
}

export default Select
