import React from "react"
import "./Message.scss"
import { Icon, IconDefinition } from "../Icon/Icon"
type MessageProps = {
  text: string
  type: IconDefinition
}
const Message = (props: MessageProps) => {
  const { text, type } = props
  return (
    <div className={`Message  Message--${type}`}>
      <Icon icon={type} />
      <p>{text}</p>
    </div>
  )
}

export default Message
