//
// WARNING
//
// Do not make manual changes to this file.
// This file was generated by generate-AllBusinessIcons-component.js.
//
//

import React from "react"

/**
 * A list of all available icons in the icon set.
 */
export type IconDefinition =
  | "category"
  | "circle-black"
  | "clear"
  | "dropdown"
  | "info"
  | "logo"
  | "menu-close"
  | "menu-open"
  | "products"
  | "sort-down"
  | "sort-up"
  | "sort"
  | "user"
  | "users"

export const availableIcons: IconDefinition[] = [
  "category",
  "circle-black",
  "clear",
  "dropdown",
  "info",
  "logo",
  "menu-close",
  "menu-open",
  "products",
  "sort-down",
  "sort-up",
  "sort",
  "user",
  "users"
]

export const IconSvg = {
  category: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M12 2l-5.5 9h11z" />
      <circle cx="17.5" cy="17.5" r="4.5" />
      <path d="M3 13.5h8v8H3z" />
    </svg>
  ),
  "circle-black": (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2z" />
    </svg>
  ),
  clear: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
    </svg>
  ),
  dropdown: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M7 10l5 5 5-5z" />
    </svg>
  ),
  info: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z" />
    </svg>
  ),
  logo: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 210 54">
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h210v54H0z" data-name="Rectangle 19" />
        </clipPath>
      </defs>
      <g clip-path="url(#a)">
        <path
          fill="#30efa6"
          d="M7.434 18.879V4.586a.834.834 0 01.416-.722L12.883.932a.835.835 0 011.254.722v19.98a.833.833 0 01-1.233.733l-5.035-2.752a.84.84 0 01-.435-.736z"
          data-name="Path 11"
        />
        <path
          fill="#30efa6"
          d="M16.4 1.547l5.035 2.932a.84.84 0 01.416.722v14.352a.84.84 0 01-.4.717l-5.035 3.05a.834.834 0 01-1.265-.717V2.269a.832.832 0 011.249-.722z"
          data-name="Path 12"
        />
        <path
          fill="#30efa6"
          d="M1.449 24.058l5.027-2.972a.832.832 0 01.824-.013L47 42.781a.834.834 0 01.435.738l-.035 5.733a.833.833 0 01-1.233.728L1.473 25.513a.836.836 0 01-.024-1.455z"
          data-name="Path 13"
        />
        <path
          fill="#30efa6"
          d="M17.313 23.957l20.26-12.271a.832.832 0 01.835-.016l5.083 2.812a.838.838 0 01.021 1.453L23.081 28.079a.839.839 0 01-.824.016l-4.912-2.689a.838.838 0 01-.032-1.449z"
          data-name="Path 14"
        />
        <path
          fill="#30efa6"
          d="M24.965 28.135l19.084-11.348a.833.833 0 011.26.709l.08 5.837a.834.834 0 01-.414.733l-14.118 8.269a.835.835 0 01-.819.013l-5.049-2.758a.839.839 0 01-.024-1.455z"
          data-name="Path 15"
        />
        <path
          fill="#30efa6"
          d="M-.333 33.022v-5.938A.833.833 0 01.9 26.351l44.71 24.482a.839.839 0 01.032 1.45l-4.982 3.03a.836.836 0 01-.83.021L.1 33.758a.837.837 0 01-.433-.736z"
          data-name="Path 16"
        />
        <path
          fill="#30efa6"
          d="M7.434 49.232v-9.943a.835.835 0 011.233-.736l5.033 2.734a.838.838 0 01.438.736v10.245a.835.835 0 01-1.265.717L7.84 49.949a.842.842 0 01-.406-.717z"
          data-name="Path 17"
        />
        <path
          fill="#30efa6"
          d="M16.376 42.731l5.035 2.734a.837.837 0 01.438.736v3.031a.833.833 0 01-.406.717l-5.035 3.036a.835.835 0 01-1.265-.717v-8.8a.836.836 0 011.233-.737z"
          data-name="Path 18"
        />
        <path
          fill="#7b8c92"
          d="M65.826 24.626c.256-.227.622-.594.971-.594a.733.733 0 01.729.744c0 .35-.227.516-.486.73l-2.82 2.341 3.425 2.853c.272.227.515.4.515.776a.732.732 0 01-.729.744 1.1 1.1 0 01-.683-.35L63.1 28.818l-.606.546v1.854c0 .5-.107 1-.728 1s-.729-.5-.729-1v-9.192c0-.5.107-1 .729-1s.728.5.728 1v5.409z"
          data-name="Path 19"
        />
        <path
          fill="#7b8c92"
          d="M79.906 31.143c0 .334.061 1.078-.712 1.078-.622 0-.729-.516-.729-1.019v-.243l-1.182.776a2.387 2.387 0 01-1.259.487h-1.075a2.01 2.01 0 01-1.348-.532 2.311 2.311 0 01-.667-1.974l-.152-4.59c-.016-.532.061-1.094.728-1.094.622 0 .7.532.713 1l.152 4.483a2.432 2.432 0 00.152.942c.12.214.5.305.848.305h.486a.809.809 0 00.456-.136l2.137-1.354v-4.237c0-.5.107-1 .728-1s.729.5.729 1v6.107z"
          data-name="Path 20"
        />
        <path
          fill="#7b8c92"
          d="M87.458 29.473c0 .819-.029 1.292.971 1.292h.742c.592 0 .865-.045 1.017-.639a.742.742 0 01.729-.591.732.732 0 01.742.73 2.176 2.176 0 01-.5 1.214 2.358 2.358 0 01-2 .744h-.742a2.366 2.366 0 01-1.878-.73 2.636 2.636 0 01-.531-2.036V25.49h-.47c-.424 0-1-.091-1-.73 0-.623.515-.73 1-.73h.47v-1.488c0-.5.107-1 .729-1s.728.5.728 1v1.488H89.9c.486 0 1 .107 1 .73 0 .639-.576.73-1 .73h-2.437v3.983z"
          data-name="Path 21"
        />
        <path
          fill="#7b8c92"
          d="M100.6 30.763h1.091c.486 0 1 .107 1 .73 0 .639-.576.73-1 .73h-3.7c-.424 0-1-.091-1-.73 0-.623.515-.73 1-.73h1.153V25.49h-1.152c-.424 0-1-.091-1-.73 0-.623.515-.73 1-.73h1.532c.832 0 1.075.153 1.075 1.019zm-2.2-9.679a.6.6 0 01.683-.685h.833a.6.6 0 01.683.685v.7a.6.6 0 01-.683.685h-.833a.6.6 0 01-.683-.685z"
          data-name="Path 22"
        />
        <path
          fill="#7b8c92"
          d="M110.469 25.49c-.424 0-1-.091-1-.73 0-.623.515-.73 1-.73h2a2.3 2.3 0 011.878.653 2.107 2.107 0 01.653 1.733l.152 4.954c.016.425-.2.851-.68.851a.74.74 0 01-.742-.73l-1 .516a1.307 1.307 0 01-.894.214h-1.396a2.27 2.27 0 01-1.652-.516 2.044 2.044 0 01-.758-1.792v-.639a1.9 1.9 0 01.713-1.626 2.384 2.384 0 011.7-.546h3.122v-.334c0-1.094-.243-1.276-1.289-1.276zm-.016 3.071c-.4 0-.971.107-.971.637v.974c0 .487.59.594.939.594h1.607l1.606-.837v-.319c0-.805-.227-1.049-1.03-1.049z"
          data-name="Path 23"
        />
        <path
          fill="#30efa6"
          d="M133.078 28.955a2.045 2.045 0 01-.91 2.079l-.864.688c-.3.238-.761.642-1.148.642h-.968c-.641 0-.939-.417-1.4-.853l-.283-.27v2.886c0 .492-.1.987-.715.987s-.715-.492-.715-.987v-9.06c0-.508.059-1.062.715-1.062.582 0 .715.463.715.942v.179l.283-.254c.374-.329.939-.867 1.342-.867h.985a2.575 2.575 0 011.192.658l.865.674a2.059 2.059 0 01.91 2.079v1.538zm-1.433-1.733a.73.73 0 00-.3-.629l-1.072-.9a.788.788 0 00-.491-.254h-.374c-.208 0-.461.27-.611.417l-.894.853a.939.939 0 00-.4.763v1.4a.871.871 0 00.209.613l1.088 1.033c.208.209.433.4.611.4h.374a.78.78 0 00.491-.254l1.072-.9a.73.73 0 00.3-.629z"
          data-name="Path 24"
        />
        <path
          fill="#30efa6"
          d="M138 26.683a1.936 1.936 0 01.91-1.87 2.558 2.558 0 012.027-.808h1.134a2.559 2.559 0 012.028.808 1.936 1.936 0 01.91 1.87v2.707a2.086 2.086 0 01-.984 1.929 2.377 2.377 0 01-1.954.746h-1.134a2.384 2.384 0 01-1.953-.746A2.092 2.092 0 01138 29.39zm1.433 2.7c0 .3.1.492.523.853a1.294 1.294 0 00.984.388h1.134a1.311 1.311 0 00.985-.388c.419-.358.523-.554.523-.853v-2.7c0-.3-.1-.492-.523-.853a1.3 1.3 0 00-.985-.388h-1.134a1.3 1.3 0 00-.984.388c-.417.358-.523.554-.523.853z"
          data-name="Path 25"
        />
        <path
          fill="#30efa6"
          d="M149.931 25.067c0-.524.059-1.062.716-1.062.565 0 .715.479.715.958v.583l1.118-.928a2.144 2.144 0 011.267-.613h1.147a1.885 1.885 0 011.4.567 2.1 2.1 0 01.641 1.629c0 .492-.088 1-.7 1-.523 0-.731-.417-.731-.883a.944.944 0 00-.15-.629c-.178-.254-.523-.254-.864-.254h-.419a1.1 1.1 0 00-.715.345l-2 1.659v3.633c0 .492-.1.987-.715.987s-.715-.492-.715-.987v-6.005z"
          data-name="Path 26"
        />
        <path
          fill="#30efa6"
          d="M161.862 26.683a1.937 1.937 0 01.909-1.87 2.56 2.56 0 012.028-.808h1.134a2.559 2.559 0 012.028.808 1.938 1.938 0 01.91 1.87v2.707a2.086 2.086 0 01-.984 1.929 2.377 2.377 0 01-1.954.746H164.8a2.383 2.383 0 01-1.953-.746 2.092 2.092 0 01-.984-1.929zm1.432 2.7c0 .3.1.492.523.853a1.3 1.3 0 00.985.388h1.134a1.311 1.311 0 00.985-.388c.419-.358.523-.554.523-.853v-2.7c0-.3-.1-.492-.523-.853a1.3 1.3 0 00-.985-.388H164.8a1.307 1.307 0 00-.985.388c-.416.358-.523.554-.523.853z"
          data-name="Path 27"
        />
        <path
          fill="#30efa6"
          d="M176.238 25.442c-.3 0-.805-.059-.805.4 0 .238.178.329.387.417l3.58 1.629a2.041 2.041 0 011.4 2.033 2.064 2.064 0 01-2.356 2.137h-2.207a2.561 2.561 0 01-1.79-.449c-.269-.209-.656-.479-.656-.837a.733.733 0 01.744-.717.691.691 0 01.552.254 1.511 1.511 0 001.148.313h1.94c.507 0 1.192.045 1.192-.792 0-.345-.416-.567-.744-.717l-3.37-1.541A1.768 1.768 0 01174 25.827 1.828 1.828 0 01175.969 24h2.447a2.072 2.072 0 011.654.492c.27.225.566.4.566.808a.73.73 0 01-.731.717.757.757 0 01-.611-.3 1.041 1.041 0 00-.881-.284h-2.175z"
          data-name="Path 28"
        />
        <path
          fill="#30efa6"
          d="M191.975 30.629h1.073c.477 0 .984.1.984.717 0 .629-.565.717-.984.717h-3.64c-.419 0-.985-.091-.985-.717s.507-.717.985-.717h1.134v-5.187h-1.134c-.419 0-.985-.091-.985-.717s.507-.717.985-.717h1.5c.819 0 1.059.15 1.059 1v5.62zm-2.161-9.523a.593.593 0 01.669-.674h.82a.593.593 0 01.672.674v.688a.594.594 0 01-.672.674h-.82a.592.592 0 01-.669-.674z"
          data-name="Path 29"
        />
        <path
          fill="#30efa6"
          d="M200.056 25.442c-.416 0-.985-.091-.985-.717s.507-.717.985-.717h1.969a2.258 2.258 0 011.849.642 2.071 2.071 0 01.641 1.7l.149 4.874c.016.42-.2.837-.672.837a.729.729 0 01-.731-.717l-.985.508a1.3 1.3 0 01-.881.209h-1.371a2.234 2.234 0 01-1.625-.508 2 2 0 01-.745-1.766v-.629a1.87 1.87 0 01.7-1.6 2.347 2.347 0 011.671-.538h3.075v-.32c0-1.075-.238-1.255-1.268-1.255zm-.013 3.02c-.387 0-.956.1-.956.629v.958c0 .479.582.583.926.583h1.58l1.58-.821V29.5c0-.792-.225-1.033-1.014-1.033h-2.116z"
          data-name="Path 30"
        />
      </g>
    </svg>
  ),
  "menu-close": (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      transform="rotate(360)"
    >
      <g fill="none">
        <path
          stroke="#626262"
          stroke-linecap="round"
          stroke-width="2"
          d="M10 12h9M5 19h14M5 5h14"
          className="il-md-length-15 il-md-duration-2 il-md-delay-2"
        />
        <path
          stroke="#626262"
          stroke-linecap="round"
          stroke-width="2"
          d="M3 15l3-3M3 9l3 3"
        />
      </g>
      <path fill="rgba(0, 0, 0, 0)" d="M0 0h24v24H0z" />
    </svg>
  ),
  "menu-open": (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      transform="rotate(360)"
    >
      <g fill="none">
        <path
          stroke="#626262"
          stroke-linecap="round"
          stroke-width="2"
          d="M14 12H5M19 19H5M19 5H5"
          className="il-md-length-15 il-md-duration-2 il-md-delay-2"
        />
        <path
          stroke="#626262"
          stroke-linecap="round"
          stroke-width="2"
          d="M21 15l-3-3M21 9l-3 3"
        />
      </g>
      <path fill="rgba(0, 0, 0, 0)" d="M0 0h24v24H0z" />
    </svg>
  ),
  products: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M3 13h2v-2H3v2zm0 4h2v-2H3v2zm0-8h2V7H3v2zm4 4h14v-2H7v2zm0 4h14v-2H7v2zM7 7v2h14V7H7z" />
    </svg>
  ),
  "sort-down": (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      transform="rotate(360)"
    >
      <path
        fill="#626262"
        d="M3.5 2.5a.5.5 0 00-1 0v8.793l-1.146-1.147a.5.5 0 00-.708.708l2 1.999.007.007a.497.497 0 00.7-.006l2-2a.5.5 0 00-.707-.708L3.5 11.293V2.5zm3.5 1a.5.5 0 01.5-.5h7a.5.5 0 010 1h-7a.5.5 0 01-.5-.5zM7.5 6a.5.5 0 000 1h5a.5.5 0 000-1h-5zm0 3a.5.5 0 000 1h3a.5.5 0 000-1h-3zm0 3a.5.5 0 000 1h1a.5.5 0 000-1h-1z"
      />
      <path fill="rgba(0, 0, 0, 0)" d="M0 0h16v16H0z" />
    </svg>
  ),
  "sort-up": (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      transform="rotate(360)"
    >
      <path
        fill="#626262"
        d="M3.5 12.5a.5.5 0 01-1 0V3.707L1.354 4.854a.5.5 0 11-.708-.708l2-1.999.007-.007a.498.498 0 01.7.006l2 2a.5.5 0 11-.707.708L3.5 3.707V12.5zm3.5-9a.5.5 0 01.5-.5h7a.5.5 0 010 1h-7a.5.5 0 01-.5-.5zM7.5 6a.5.5 0 000 1h5a.5.5 0 000-1h-5zm0 3a.5.5 0 000 1h3a.5.5 0 000-1h-3zm0 3a.5.5 0 000 1h1a.5.5 0 000-1h-1z"
      />
      <path fill="rgba(0, 0, 0, 0)" d="M0 0h16v16H0z" />
    </svg>
  ),
  sort: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M3 18h6v-2H3v2zM3 6v2h18V6H3zm0 7h12v-2H3v2z" />
    </svg>
  ),
  user: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" />
    </svg>
  ),
  users: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5c-1.66 0-3 1.34-3 3s1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5C6.34 5 5 6.34 5 8s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5zm8 0c-.29 0-.62.02-.97.05 1.16.84 1.97 1.97 1.97 3.45V19h6v-2.5c0-2.33-4.67-3.5-7-3.5z" />
    </svg>
  )
}
