import React from "react"
import { NavLink } from "react-router-dom"
import { useUIContext } from "../../../lib/context/UIContext/UIContext"
import { useWindowWidth } from "../../../lib/hooks/useWindowWidth"
import { Icon, IconDefinition } from "../../shared/Icon/Icon"
import "./NavItem.scss"

interface NavItemProps {
  iconTitle: IconDefinition
  linkTitle?: string | ""
  path?: any
}

const NavItem = (props: NavItemProps) => {
  const uiContext = useUIContext()
  const windowWidth = useWindowWidth()

  return (
    <NavLink
      activeClassName="NavItem--active"
      to={props.path}
      className="NavItem"
      onClick={() => windowWidth < 992 && uiContext.toggleSideBarAndMenu()}
    >
      <Icon icon={props.iconTitle} className="NavItem__my-icon" />
      {props.linkTitle}
    </NavLink>
  )
}

export default NavItem
