import React from "react"
import { Form } from "reactstrap"
import { Icon } from "../shared/Icon/Icon"
import { Spinner } from "reactstrap"
import { LoginFormik } from "./lib/useLoginFormik"
import { ErrorHandler } from "../../components/shared/ErrorHandler/ErrorHandler"
import Button from "../shared/Button/Button"
import Input from "../shared/Input/Input"
import "./LoginForm.scss"

interface LoginFormProps {
  formik: LoginFormik
  error?: string
}

export const LoginForm = (props: LoginFormProps) => {
  const { formik } = props
  return (
    <div className="LoginForm">
      <Icon icon="logo" className="LoginForm__icon" />
      <h4>Log In</h4>
      <Form className="LoginForm__form" onSubmit={formik.handleSubmit}>
        <Input
          type="email"
          name="email"
          id="exampleEmail"
          placeholder="Write your email"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          invalid={!!formik.errors.email && formik.touched.email}
          error={
            formik.errors.email && formik.touched.email
              ? formik.errors.email
              : ""
          }
        />
        <Input
          className="LoginForm-input"
          type="password"
          name="password"
          id="examplePassword"
          placeholder="Write your password"
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          invalid={!!formik.errors.password && formik.touched.password}
          error={
            formik.errors.password && formik.touched.password
              ? formik.errors.password
              : ""
          }
        />
        <ErrorHandler error={props.error} />
        <Button color="secondary" type="submit" className="LoginForm-button">
          {formik.isSubmitting ? (
            <div className="LoginForm__spinner">
              <Spinner size="sm" />
            </div>
          ) : (
            "Log In"
          )}
        </Button>
      </Form>
    </div>
  )
}
