import React, { useState } from "react"
import { RouteComponentProps } from "react-router-dom"
import { useConfirmation } from "../../lib/context/ConfirmationContext/ConfirmationContext"
import { useApiCall } from "../../lib/hooks/useApiCall"
import { CustomSpinner } from "../../components/shared/CustomSpinner/CustomSpinner"
import Table from "../../components/shared/Table/Table"
import Button from "../../components/shared/Button/Button"
import * as API from "../../api/Api"
import "./Users.scss"
import { useErrorHandler } from "../../lib/hooks/useErrorHandler"
import { ErrorMessage } from "../../components/shared/ErrorMessage/ErrorMessage"

const Users = (props: RouteComponentProps) => {
  const confirmationContext = useConfirmation()
  const errorHandler = useErrorHandler()
  const [deleting, setDeleting] = useState<boolean>(false)
  const [deleteCheck, setDeleteCheck] = useState<string>()
  const { loading, data: users, error, reload } = useApiCall(API.getAllUsers)

  const handleDelete = async (userId: string) => {
    try {
      await confirmationContext.confirm("A jeni i sigurte?")
      setDeleteCheck(userId)
      setDeleting(true)
      const res = await API.deleteUser(userId)
      setDeleting(false)
      reload()
    } catch (e) {
      errorHandler.handleError(e)
    }
  }

  const columns = [
    {
      key: "userName",
      header: "Username"
    },
    {
      key: "email",
      header: "Email"
    },
    {
      key: "product_actions",
      header: "Actions"
    }
  ]
  const rows = users?.users.map((user) => ({
    userName: <>{user.full_name}</>,
    displayName: <>{user.username}</>,
    email: <>{user.email}</>,
    product_actions: (
      <>
        {deleteCheck === user.id ? (
          <Button color="danger" disabled={deleting}>
            <CustomSpinner />
          </Button>
        ) : (
          <Button onClick={() => handleDelete(user.id)} color="danger">
            Delete
          </Button>
        )}
      </>
    )
  }))
  return (
    <div className="Users">
      <Table
        loading={loading}
        title="Users"
        columns={columns}
        rows={rows}
        actions={
          <Button onClick={() => props.history.push("user/new")}>
            Add User
          </Button>
        }
      />
      <ErrorMessage error={errorHandler.error || error} />
    </div>
  )
}

export default Users
