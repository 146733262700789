import React from "react"
import { Button as ButtonRS } from "reactstrap"
import cs from "classnames"
import "./Button.scss"

interface ButtonProps {
  type?: "button" | "submit" | "reset" | undefined
  className?: string
  color?: string
  children?: React.ReactNode
  label?: string | ""
  disabled?: boolean
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  checkId?: string
}

const Button = (props: ButtonProps) => {
  const { className, onClick, ...rest } = props

  return (
    <ButtonRS
      {...rest}
      className={cs("Button", props.className)}
      onClick={onClick}
    >
      {props.children}
    </ButtonRS>
  )
}

export default Button
