import React, { useState } from "react"
import { Alert } from "reactstrap"

interface Props {
  error: string
}
export const ErrorMessage = (props: Props) => {
  const [visible, setVisible] = useState(true)

  const onDismiss = () => setVisible(false)

  return props.error ? (
    <Alert color="danger" isOpen={visible} toggle={onDismiss}>
      {props.error}
    </Alert>
  ) : (
    <div></div>
  )
}
