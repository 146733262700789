import React, { useContext } from "react"

export interface UIContextType {
  isSideBarOpen: boolean
  isMenuOpen: boolean
  toggleSideBar: () => void
  toggleMenu: () => void
  toggleSideBarAndMenu: () => void
}
const UIContextValues: UIContextType = {
  isSideBarOpen: true,
  isMenuOpen: true,
  toggleSideBar: () => {},
  toggleMenu: () => {},
  toggleSideBarAndMenu: () => {}
}
export const UIContext = React.createContext<UIContextType>(UIContextValues)

export const useUIContext = () => useContext(UIContext)
