import React from "react"
import TableSkeleton from "../shared/Table/TableSkeleton/TableSkeleton"

interface Props {
  loading?: boolean

  children: React.ReactNode
}

export const HandleLoadingState = (props: Props) => {
  if (!props.loading) {
    return <>{props.children}</>
  }
  return <TableSkeleton />
}
