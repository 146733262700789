import React from "react"

export function useOutsideClickDetection(
    ref: React.MutableRefObject<any>,
    callback: (target?: any) => void
) {
    React.useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                callback(event.target)
            }
        }

        document.addEventListener("mousedown", handleClickOutside)
        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [ref]) // eslint-disable-line react-hooks/exhaustive-deps
}
