import React from "react"
import { RouteComponentProps } from "react-router-dom"
import { useCategoryFormik } from "../../../components/CategoryForm/lib/useCategoryFormik"
import { useErrorHandler } from "../../../lib/hooks/useErrorHandler"
import CategoryForm from "../../../components/CategoryForm/CategoryForm"
import * as API from "../../../api/Api"

import "./AddCategory.scss"
import { ErrorMessage } from "../../../components/shared/ErrorMessage/ErrorMessage"

interface Props {
  id: string
}

const AddCategory = (props: RouteComponentProps<Props>) => {
  const errorHandler = useErrorHandler()
  const formik = useCategoryFormik({
    onSubmit: async (values, formikHelpers) => {
      try {
        const formData = new FormData()
        formData.append("name", values.category_name)
        formData.append("image", values.selectedCategoryPhoto)
        const res = await API.createCategory(formData)
        formikHelpers.resetForm()
        props.history.push("/categories")
      } catch (e) {
        errorHandler.handleError(e)
      } finally {
      }
    }
  })

  return (
    <div className="AddCategory">
      <CategoryForm
        button_label="Submit"
        title="Add Category"
        formik={formik}
        error={errorHandler.error}
      />
    </div>
  )
}

export default AddCategory
