import React, { useState } from "react"
import { CustomSpinner } from "../../components/shared/CustomSpinner/CustomSpinner"
import { RouteComponentProps } from "react-router-dom"
import { useConfirmation } from "../../lib/context/ConfirmationContext/ConfirmationContext"
import { useApiCall } from "../../lib/hooks/useApiCall"
import { useErrorHandler } from "../../lib/hooks/useErrorHandler"
import { ErrorMessage } from "../../components/shared/ErrorMessage/ErrorMessage"
import Table from "../../components/shared/Table/Table"
import Button from "../../components/shared/Button/Button"
import Image from "../../components/shared/Image/Image"
import * as API from "../../api/Api"

import "./Categories.scss"

export interface CategoryType {
  id: string
  name: string
  media: string
}
const Categories = (props: RouteComponentProps) => {
  const confirmationContext = useConfirmation()
  const errorHandler = useErrorHandler()
  const [deleteCheck, setDeleteCheck] = useState<string>()
  const [deleting, setDeleting] = useState(false)
  const { loading, data: categories, error, reload } = useApiCall(
    API.getAllCategories
  )
  const handleClick = async (categoryId: string) => {
    await confirmationContext.confirm("A jeni i sigurte?")
    setDeleteCheck(categoryId)
    try {
      setDeleting(true)
      await API.deleteCategory(categoryId)
      reload()
      setDeleting(false)
    } catch (e) {
      errorHandler.handleError(e)
    }
  }

  const columns = [
    {
      key: "category_photo",
      header: "Picture"
    },

    {
      key: "category_name",
      header: "Name",
      sortable: true
    },

    {
      key: "category_actions",
      header: "Actions"
    }
  ]

  const rows = categories?.category.map((category) => ({
    category_photo: (
      <div className="product_photo">
        <Image file={category.media} alt="category" />
      </div>
    ),
    category_name: category.name,

    category_actions: (
      <>
        {deleteCheck === category.id ? (
          <Button disabled={deleting} color="danger">
            <CustomSpinner />
          </Button>
        ) : (
          <Button onClick={() => handleClick(category.id)} color="danger">
            Delete
          </Button>
        )}

        <Button
          color="secondary"
          className="Categories__change-btn"
          onClick={() => props.history.push(`categories/edit/${category.id}`)}
        >
          Edit
        </Button>
      </>
    )
  }))

  return (
    <div className="Categories">
      <Table
        loading={loading}
        title="Categories"
        columns={columns}
        rows={rows}
        actions={
          <Button onClick={() => props.history.push("categories/new")}>
            Add Category
          </Button>
        }
      />
      <ErrorMessage error={errorHandler.error || error} />
    </div>
  )
}
export default Categories
