import React from "react"
import ReactDOM from "react-dom"
import {BrowserRouter as Router} from "react-router-dom"
import * as serviceWorker from "./serviceWorker"
import "./styles/main.scss"
import App from "./App"

import {AuthContextProvider} from "./lib/context/AuthContext/AuthContextProvider"
import {UIContextProvider} from "./lib/context/UIContext/UIContextProvider"
import {ConfirmationContextProvider} from "./lib/context/ConfirmationContext/ConfirmationContextProvider"

ReactDOM.render(
    <React.StrictMode>
        <Router>
            <AuthContextProvider>
                <UIContextProvider>
                    <ConfirmationContextProvider>
                        <App/>
                    </ConfirmationContextProvider>
                </UIContextProvider>
            </AuthContextProvider>
        </Router>
    </React.StrictMode>,
    document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
