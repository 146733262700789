import * as Yup from "yup"
import { Formik, FormikHelpers, useFormik } from "formik"

export interface ProductFormFields {
  cost: string
  selectPhoto: File | string
  product_name: string
  selectCategory: string
}

interface UseProductFormOptions {
  initialValues?: ProductFormFields
  onSubmit: (
    values: ProductFormFields,
    formikHelpers: FormikHelpers<ProductFormFields>
  ) => Promise<any>
}

export const useProductFormik = (options: UseProductFormOptions) => {
  const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/gif",
    "image/png"
  ]
  const ProductFormSchema = Yup.object().shape({
    product_name: Yup.string().required("Please add a product!"),
    selectCategory: Yup.string().required("Please choose a category!"),
    selectPhoto: Yup.mixed().required("Image is required!"),
    cost: Yup.string().required("Please add price!")
  })
  return useFormik({
    initialValues: {
      product_name: "",
      selectCategory: "",
      selectPhoto: "",
      cost: "",
      ...options.initialValues
    },
    // validateOnMount: true,
    isInitialValid: false,
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: ProductFormSchema,
    onSubmit: async (values, formikHelpers) => {
      await options.onSubmit(values, formikHelpers)
    }
  })
}
export type ProductFormik = ReturnType<typeof useProductFormik>
