import React, { useCallback, useEffect, useState } from "react"
import { useCategoryFormik } from "../../components/CategoryForm/lib/useCategoryFormik"
import CategoryForm from "../CategoryForm/CategoryForm"
import * as API from "../../api/Api"
import "./EditCategory.scss"
import { useRouter } from "../../lib/hooks/useRouter"
import { useApiCall } from "../../lib/hooks/useApiCall"
import { useErrorHandler } from "../../lib/hooks/useErrorHandler"

export interface EditCategoryFormProps {
  category: { category: { id: string; name: string } }
}

interface Props {
  category: API.CategoryResponse
  categoryId: string
}

const EditCategoryForm = (props: Props) => {
  const { category, categoryId } = props
  const router = useRouter()
  const errorHandler = useErrorHandler()

  const formik = useCategoryFormik({
    initialValues: {
      category_name: category.category.name,
      selectedCategoryPhoto: category.category.media
    },

    onSubmit: async (values, formikHelpers) => {
      try {
        const formData = new FormData()
        formData.append("name", values.category_name)
        formData.append("image", values.selectedCategoryPhoto)
        formData.append("categoryId", categoryId)
        const res = await API.updateCategory(formData)
        formikHelpers.resetForm()
        router.history.push("/categories")
      } catch (e) {
        errorHandler.handleError(e)
      } finally {
      }
    }
  })
  return (
    <CategoryForm
      button_label="Submit changes"
      title="Edit Category"
      formik={formik}
      error={errorHandler.error}
    />
  )
}

export default EditCategoryForm
