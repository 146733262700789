import React, { useCallback, useEffect, useState } from "react"
import { RouteComponentProps } from "react-router-dom"
import * as API from "../../../api/Api"
import "./EditProduct.scss"
import { EditProductForm } from "../../../components/EditProductForm/EditProductForm"
import { useApiCall } from "../../../lib/hooks/useApiCall"
import { ProductFormSkeleton } from "../../../components/ProductForm/ProductFormSkeleton/ProductFormSkeleton"
interface Props {
  id: string
}

const EditProduct = (props: RouteComponentProps<Props>) => {
  const productId = props.match.params.id
  const { loading, data: product, error } = useApiCall(API.getProductById, [
    productId
  ])
  return loading ? (
    <ProductFormSkeleton />
  ) : (
    <div className="EditProduct">
      {product && <EditProductForm productId={productId} product={product} />}
    </div>
  )
}

export default EditProduct
