import React from "react"
import { RouteComponentProps } from "react-router"
import { useUserFormik } from "./lib/useUserFormik"
import { CustomSpinner } from "../../components/shared/CustomSpinner/CustomSpinner"
import { useState } from "react"
import Select from "../../components/shared/Select/Select"
import Input from "../../components/shared/Input/Input"
import Button from "../../components/shared/Button/Button"
import * as API from "../../api/Api"

import "./AddUser.scss"

interface Props {
  id: string
}
const options = [
  { value: "1", label: "Admin" },
  { value: "2", label: "Guest" }
]
export const AddUser = (props: RouteComponentProps<Props>) => {
  const [error, setError] = useState<string>()
  const [value, setValue] = useState<string>()
  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const option = options.find((opt) => {
      return opt.value === e.target.value
    })
    setValue(option?.label)
  }
  const formik = useUserFormik({
    onSubmit: async (values, formikHelpers) => {
      try {
        console.log(values)
        const res = await API.register({
          full_name: values.full_name,
          password: values.password,
          role: value?.toLowerCase(),
          email: values.email
        })
        formikHelpers.resetForm()
        props.history.push("/users")
      } catch (e) {
        setError(e.response.data.message)
      } finally {
      }
    }
  })

  return (
    <div className="AddUser">
      <div className="AddUser__header">
        <h5>Add User</h5>
      </div>
      <div className="AddUser__content">
        <form onSubmit={formik.handleSubmit}>
          <div className="AddUser__content-left">
            <Input
              type="text"
              name="full_name"
              id="full_name"
              placeholder={formik.values.full_name ? "" : "Full name"}
              value={formik.values.full_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              invalid={!!(formik.errors.full_name && formik.touched.full_name)}
              error={
                formik.touched.full_name && formik.errors.full_name
                  ? formik.errors.full_name
                  : ""
              }
            />
            <Input
              type="text"
              name="email"
              id="email"
              placeholder={formik.values.email ? "" : "Email"}
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              invalid={!!(formik.errors.email && formik.touched.email)}
              error={
                formik.touched.email && formik.errors.email
                  ? formik.errors.email
                  : ""
              }
            />

            <Input
              type="password"
              name="password"
              id="password"
              placeholder={formik.values.password ? "" : "Password"}
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              invalid={!!(formik.errors.password && formik.touched.password)}
              error={
                formik.touched.password && formik.errors.password
                  ? formik.errors.password
                  : ""
              }
            />
            <Select
              type="select"
              disabledOption="Select role"
              name="selectRole"
              options={options}
              onChange={handleChange}
              className="AddUser__select"
            />

            {error && <div>{error}</div>}
            <Button
              className="AddUser-button"
              color="secondary"
              type="submit"
              disabled={!formik.isValid}
            >
              {formik.isSubmitting ? <CustomSpinner /> : "Submit"}
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}
