import React, { useRef, useState } from "react"

import { Icon } from "../../components/shared/Icon/Icon"
import { useAuthContext } from "../../lib/context/AuthContext/AuthContext"
import { useUIContext } from "../../lib/context/UIContext/UIContext"
import { useOutsideClickDetection } from "../../lib/hooks/useOutsideClickDetection"

//styles
import "./Header.scss"
import cs from "classnames"

export const Header = (props: any) => {
  const authCtx = useAuthContext()
  const uiContext = useUIContext()
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false)
  const ref = useRef<HTMLDivElement | null>(null)
  useOutsideClickDetection(ref, () => closeDropdown())

  const toggleDropdown = () => {
    setIsDropdownOpen((prevState) => !prevState)
  }
  const closeDropdown = () => {
    setIsDropdownOpen(false)
  }

  const logout = () => authCtx.logout()
  return (
    <div className="Header">
      <Icon
        onClick={() => uiContext.toggleSideBarAndMenu()}
        icon={uiContext.isMenuOpen ? "menu-open" : "menu-close"}
        className="Header__menu-icon"
      />

      <div ref={ref} className="Header__user-profile">
        <Icon icon="user" className="Header__user-icon" />
        <h6 onClick={() => toggleDropdown()}>{authCtx.user?.fullName}</h6>
        <div className="Header__dropdown">
          <button
            className="Header__dropdown-btn"
            onClick={(event) => toggleDropdown()}
          >
            <Icon icon="dropdown" />
          </button>
          <div
            className={cs(
              "Header__dropdown__content",
              isDropdownOpen && "Header__dropdown__content--show"
            )}
          >
            <a href="#" onClick={() => logout()}>
              Dil
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
