import * as Yup from "yup"
import { FormikHelpers, useFormik } from "formik"

export interface AddUserFields {
  full_name: string
  email: string
  password: string
  role: string
}

interface AddUserOptions {
  initialValues?: AddUserFields
  onSubmit: (
    values: AddUserFields,
    formikHelpers: FormikHelpers<AddUserFields>
  ) => Promise<any>
}

export const useUserFormik = (options: AddUserOptions) => {
  const ProductFormSchema = Yup.object().shape({
    full_name: Yup.string().required("Please enter your name"),

    email: Yup.string()
      .email("Please enter your email address in the right format!")
      .required("Please enter your email address!"),
    password: Yup.string()
      .min(8, "Your password must be between 8 and 30 characters long !")
      .required("Please add password!")
  })
  return useFormik({
    initialValues: {
      full_name: "",
      email: "",
      password: "",
      role: "",
      ...options.initialValues
    },
    validateOnMount: true,
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: ProductFormSchema,
    onSubmit: async (values, formikHelpers) => {
      await options.onSubmit(values, formikHelpers)
    }
  })
}
export type ProductFormik = ReturnType<typeof useUserFormik>
