import Axios from "axios"
import React, { useEffect, useState } from "react"

import * as API from "../../../api/Api"
import { LoginRegisterResponse } from "../../../api/Api"

import { AuthContext, AuthContextType } from "./AuthContext"

interface AuthContextProviderProps {
  children: React.ReactNode | null
}

const LOCAL_STORAGE_KEY = "PROJECT_KUTIA_POROSIA_DASHBOARD"

export const AuthContextProvider = (props: AuthContextProviderProps) => {
  // const [authToken] = useLocalStorage(LOCAL_STORAGE_KEY)

  const [user, setUser] = useState<API.User | undefined>(undefined)
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<
    string | { [key: string]: string } | undefined
  >(undefined)

  useEffect(() => {
    checkAuthentication()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const checkAuthentication = async () => {
    const authToken = localStorage.getItem(LOCAL_STORAGE_KEY)
    Axios.defaults.headers.common.Authorization = `Bearer ${authToken}`
    if (!authToken) {
      setTimeout(() => {
        setLoading(false)
      }, 2000)
      return
    }
    try {
      const res = await API.getUserDetails()
      const user = res.user[0]
      setUser({
        user_id: user?.id,
        email: user.email,
        fullName: user.full_name
      })

      if (!res.user) {
        return
      }
    } catch (err) {
      setError(err.toString())
    } finally {
      setLoading(false)
    }
  }
  const login = (credentials: LoginRegisterResponse) => {
    Axios.defaults.headers.common.Authorization = `Bearer ${credentials.token}`
    setUser({
      user_id: user?.user_id,
      email: credentials.user.email,
      fullName: credentials.user.full_name,
      username: credentials.user.username,
      role: credentials.user.role,
      password: credentials.user.password
    })
    localStorage.setItem(LOCAL_STORAGE_KEY, credentials.token)
  }
  const logout = () => {
    setUser(undefined)
    localStorage.removeItem(LOCAL_STORAGE_KEY)
  }

  const context: AuthContextType = {
    isAuthenticated: !!user,
    isLoading: loading,
    error: error,
    user: user,
    login: login,
    logout: logout,
    checkAuthentication
  }

  return (
    <AuthContext.Provider value={context}>
      {props.children}
    </AuthContext.Provider>
  )
}
