import React, { useEffect, useState } from "react"
import "./Image.scss"
interface ImageProps {
  file: string | File
  alt: string
}

const defaultPath = require("../../../assets/images/photoPlaceholder.png")

const Image = (props: ImageProps) => {
  const { file } = props

  const [imgUpload, setImgUpload] = useState<string | ArrayBuffer | null>("")

  useEffect(() => {
    setImgUpload(null)
    if (file === null) return
    if (typeof file === "object") {
      let reader = new FileReader()
      reader.readAsDataURL(file as any)
      reader.onload = () => {
        setImgUpload(reader.result)
      }
      return
    }
    setImgUpload(props.file as string)
  }, [props.file])

  return (
    <img
      src={imgUpload ? (imgUpload as any) : defaultPath}
      alt={props.alt || "Image Placeholder"}
    />
  )
}
export default Image
