import React, { useState } from "react"
import { RouteComponentProps } from "react-router-dom"
import { useLoginFormik } from "../../components/LoginForm/lib/useLoginFormik"
import { useAuthContext } from "../../lib/context/AuthContext/AuthContext"
import { LoginForm } from "../../components/LoginForm/LoginForm"
import * as API from "../../api/User"
import { useRouter } from "../../lib/hooks/useRouter"

//styles
import "./Login.scss"
import { Icon } from "../../components/shared/Icon/Icon"
import { useErrorHandler } from "../../lib/hooks/useErrorHandler"

interface Props {}

export const Login = (props: RouteComponentProps<Props>) => {
  const authCtx = useAuthContext()

  const errorHandler = useErrorHandler()

  const formik = useLoginFormik({
    onSubmit: async (values, formikHelpers) => {
      try {
        const res = await API.login(values)
        authCtx.login(res)
        formikHelpers.resetForm()
      } catch (e) {
        errorHandler.handleError(e)
      }
    }
  })
  return (
    <div className="Login">
      <LoginForm formik={formik} error={errorHandler.error} />
    </div>
  )
}
