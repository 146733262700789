import React from "react"

import NavItem from "../NavItem/NavItem"
import "./Nav.scss"

const Nav = () => {
  return (
    <div className="Nav">
      <NavItem iconTitle="products" linkTitle="Products" path="/products" />
      <NavItem iconTitle="category" linkTitle="Categories" path="/categories" />
      <NavItem iconTitle="users" linkTitle="Users" path="/users" />
    </div>
  )
}

export default Nav
