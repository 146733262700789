import { apiRequest } from "./Api"
export interface CategoryInput {
  name: string
  image: string
  categoryId?: string
}
export interface CategoriesResponse {
  category: [
    {
      id: string
      name: string
      media: string
    }
  ]
}
export interface CategoryResponse {
  category: {
    id: string
    name: string
    media: string
  }
}

export const createCategory = async (data: FormData) =>
  apiRequest<FormData, undefined>("post", "dashboard/category/create", data)

export const getAllCategories = async () =>
  apiRequest<undefined, CategoriesResponse>("get", "dashboard/category/get-all")

export const deleteCategory = async (categoryId: string) => {
  return apiRequest<string, undefined>(
    "delete",
    `dashboard/category/delete?categoryId=${categoryId}`
  )
}

export const getCategoryById = async (categoryId: string) =>
  apiRequest<string, CategoryResponse>(
    "get",
    `dashboard/category/category-by-id?categoryId=${categoryId}`
  )
export const updateCategory = async (data: FormData) => {
  return apiRequest<FormData, undefined>(
    "post",
    "dashboard/category/update",
    data
  )
}
