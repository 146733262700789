import { FormikHelpers, useFormik } from "formik"
import * as Yup from "yup"

export interface LoginFields {
  email: string
  password: string
}

interface UseLoginFormOptions {
  initialValues?: LoginFields
  onSubmit: (
    values: LoginFields,
    formikHelpers: FormikHelpers<LoginFields>
  ) => Promise<any>
}

export const useLoginFormik = (options: UseLoginFormOptions) => {
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Please enter your email address in the right format!")
      .required("Please enter your email address!"),
    password: Yup.string()
      .min(6, "Your password must be between 8 and 30 characters long !")
      .required("Please add password!")
  })

  return useFormik({
    initialValues: {
      email: "",
      password: ""
    },
    validateOnMount: true,
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: LoginSchema,
    onSubmit: async (values, formikHelpers) => {
      await options.onSubmit(values, formikHelpers)
    }
  })
}

export type LoginFormik = ReturnType<typeof useLoginFormik>
