import { apiRequest } from "./Api"

export interface LoginRegisterResponse {
  token: string
  error?: string
  user: {
    email: string
    full_name: string
    id: string
    password: string
    role: string
    username: string
  }
}

export interface RegisterInput {
  email: string
  password: string
  full_name: string
  role?: string
}

export interface LoginInput {
  email: string
  password: string
}

export interface User {
  user_id?: string
  fullName: string
  email: string
  password?: string
  role?: string
  username?: string
}

export interface UserDetailsResponse {
  user: Array<{
    id: string
    full_name: string
    email: string
  }>
}
export interface AllUsersResponse {
  users: [
    {
      email: string
      full_name: string
      id: string
      password: string
      role: string
      username: string
    }
  ]
}

export const register = async (data: RegisterInput) =>
  apiRequest<RegisterInput, LoginRegisterResponse>(
    "post",
    "dashboard/register",
    data
  )

export const login = async (data: LoginInput) =>
  apiRequest<LoginInput, LoginRegisterResponse>("post", "dashboard/login", data)

export const getUserDetails = async () =>
  apiRequest<undefined, UserDetailsResponse>("get", "dashboard/me")

export const deleteUser = async (userId: string) =>
  apiRequest<string, undefined>(
    "delete",
    `dashboard/delete-user?user_id=${userId}`
  )

export const getAllUsers = async () =>
  apiRequest<undefined, AllUsersResponse>("get", "dashboard/all")
